// 
// maps.scss
//


//Google Maps

.gmaps,
.gmaps-panaroma {
    height: 300px;
    background: $light;
    border-radius: 3px;
}

.gmaps-overlay {
    display: block;
    text-align: center;
    color: $white;
    font-size: 16px;
    line-height: 40px;
    background: $cyan;
    border-radius: 4px;
    padding: 10px 20px;
}

.gmaps-overlay_arrow {
    left: 50%;
    margin-left: -16px;
    width: 0;
    height: 0;
    position: absolute;
}

.gmaps-overlay_arrow.above {
    bottom: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid $cyan;
}

.gmaps-overlay_arrow.below {
    top: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid $cyan;
}

//Vector Maps

.jqvmap-label {
    background: $dark;
    padding: 0.3rem 0.6rem;
    font-family: $font-family-base;
}

.jqvmap-zoomin, .jqvmap-zoomout {
    width: 24px;
    height: 24px;
    line-height: 15px;
}

.jqvmap-zoomout {
    top: 40px;
}
