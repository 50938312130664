//
// common.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    margin: 0;
    overflow-x: hidden;
    font-size: $font-size-base;
    background-color: $bg-body;
}
