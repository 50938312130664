//
// buttons.scss
//

button:focus {
    outline: none;
}

.btn-rounded {
    border-radius: 2em;
}
