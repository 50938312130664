/** App Custom **/
html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

// Invalid indication of an input
input.ng-touched.ng-invalid {
    border-color: #dc3545 !important;
}

.c-pointer {
    cursor: pointer !important;
}

@media (min-width: 768px) {
    .modal-xl {
        width: 90%;
        max-width: 1200px;
    }
}

.logo.logo-light {
    background: white !important;
}

// Bootstrap 4.2's .toast class conflict with Toastr plugin
.toast {
    opacity: 1 !important;
}


// Theme fix
.block-ui-wrapper.content {
    margin: 0 !important;
}


.content-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
